import React from "react";
import "./footer.css";

import ETISLogo from "../../assets/svg/ETISLogo.svg";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footerSections">
        <div className="footerSection">
          <h4>
            <b>Contatti</b>
          </h4>
          <h4 className="footerSectionSubtitle">CAMPANIA</h4>
          <p>Tel. 081 7724 111</p>
          <p>Fax Vendite 081 772 4151</p>
          <p>Fax Amministrazione 081 772 4145</p>
          <a href="mailto:napoli@euroluxincasso.it">napoli@euroluxincasso.it</a>
          <br />
          <h4 className="footerSectionSubtitle">PUGLIA</h4>
          <p>Tel. 080 535 6292</p>
          <p>Fax 080 589 9296</p>
          <a href="mailto:bari@euroluxincasso.it">bari@euroluxincasso.it</a>
          <br />
        </div>
        <div className="footerSection">
          <h4>
            <b>Sedi</b>
          </h4>
          <h4 className="footerSectionSubtitle">CAMPANIA</h4>
          <div>80026 CASORIA (NA)</div>
          <div>Via Naz. Delle Puglie, 286</div>
          <br />
          <h4 className="footerSectionSubtitle">PUGLIA</h4>
          <div>70124 MODUGNO (BA)</div>
          <div>S.P. 231 (EX S.S. 98), km 80+350</div>
          <br />
        </div>

        <div className="footerSection">
          <h4>
            <b>Azienda</b>
          </h4>
          <p>
            EUROLUX INCASSO srl © 2011 - Sede legale: 80122 Napoli – Via S.
            Lucia,36 <br /> PARTITA IVA E CODICE FISCALE IT 07688260632 - R.E.A.
            647424 - Capitale sociale I.V. € 2.000.000,00
          </p>
        </div>
      </div>
      <a href="https://www.etis-software.it" className="logoETIS">
        Powered by <img src={ETISLogo} alt="ETIS" draggable="false" />
      </a>
    </div>
  );
}
